import { faSearch } from "@fortawesome/free-solid-svg-icons"

export const faqsPage = {
  title: `<span>FAQs</span>`,
  description: `Need help with something? Here are our most frequently asked question.`,
  googleReviewText: `Care Casa’s SDA home services have completely transformed my life. I have gained the independence and comfort I desire. I highly recommend them!`,
  formDetails: {
    formFieldIcon: faSearch,
    formFieldPlaceholderText: `Search what you need...`,
    buttonText: `Search`,
  },
}

// Faqs Section
export const faqs = [
  {
    question: `Who is eligible to live at Care Casa?`,
    answer: `Care Casa welcomes individuals with disabilities who require specialist disability accommodation arrangements. Our homes are designed to accommodate various needs, and we work closely with potential residents and their families to ensure suitability and compatibility.`,
  },
  {
    question: `How does Care Casa assist with navigating the National Disability Insurance Scheme (NDIS)?`,
    answer: `We have dedicated NDIS coordination services to help residents navigate the complexities of the NDIS. Our team assists with planning, funding applications, and connecting residents with the right support and services under their NDIS plan.`,
  },
  {
    question: `What types of accommodations does Care Casa offer?`,
    answer: `Care Casa offers a variety of accommodation options tailored to meet the diverse needs of our residents. These include single-bedroom apartments, shared living arrangements, and family-style homes, all with accessibility features and designed for comfort.`,
  },
  {
    question: `What support services are available at Care Casa?`,
    answer: `Our support services are comprehensive and personalised. They include assistance with daily living activities, personal care support, community participation programs, and access to healthcare services. Each resident receives a personalised support plan based on their needs and preferences.`,
  },
  {
    question: `How does Care Casa promote community engagement among residents?`,
    answer: `We prioritise community engagement through various activities and programs. Residents can participate in social events, recreational activities, and educational workshops. These initiatives foster a sense of belonging and encourage social interaction among residents.`,
  },
  {
    question: `What safety and accessibility features are available in Care Casa homes?`,
    answer: `Our homes are designed with safety and accessibility in mind. Features include wheelchair-accessible entrances, modified bathrooms and kitchens, emergency call systems, secure parking options, and accessibility to common areas within the community.`,
  },
  {
    question: `How can I schedule a tour of Care Casa or apply for residency?`,
    answer: `We encourage anyone interested in learning about Care Casa to schedule a tour of our facilities. During the tour, you'll see our accommodations and meet our team. To apply for residency, contact us directly, and we'll guide you through the application process, including eligibility requirements and availability.`,
  },
  // More questions...
]
